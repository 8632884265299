import { useState, useEffect } from "react";
import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import axios from "axios";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { CssBaseline, Dialog } from "@mui/material";
import { de } from "date-fns/locale";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useSelector } from "react-redux";
import store from "./redux/store";
import {
  logOut,
  setIsAdmin,
  setUserFavorites,
  setErrorMessage,
  setSettings,
  setInstagramAccounts,
} from "./redux/actions/index";
import Header from "./components/Header";
import Projects from "./components/projects/Projects";
import EditProject from "./components/EditProject";
import CreateProject from "./components/CreateProject";
import Admin from "./components/Admin";
import Login from "./components/auth/Login";
import LoginNope from "./components/auth/LoginNope";
import Auth from "./components/Auth";
import Report from "./components/report/Report";
import ErrorMessage from "./components/ErrorMessage";
import Footer from "./components/Footer";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#f7f7fa",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(2),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      paddingTop: "80px",
      paddingBottom: "160px",
      maxWidth: "873px",
      margin: "auto",
      minHeight: "calc(100vh - 48px)",
      width: `calc(100%)`,
      backgroundColor: "#f7f7fa",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "20px",
      },
    },
    contentConditionalPadding: {
      flexGrow: 1,
      padding: theme.spacing(2),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      paddingTop: "90px",
      paddingBottom: "90px",
      maxWidth: "873px",
      margin: "auto",
      minHeight: "calc(100vh - 48px)",
      width: `calc(100%)`,

      backgroundColor: "#f7f7fa",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: "20px",
        paddingLeft: "0px",
        paddingRight: "0px",
        width: "96%",
        margin: "auto",
      },
    },
    modalPaper: {
      backgroundColor: "#f7f7fa",
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      maxWidth: "800px",
      outline: "none",
      maxHeight: "80vh",
      overflowY: "scroll",
      overflowX: "hidden",
    },
  }),
);

const App = () => {
  const classes = useStyles();
  const [adminDialogIsOpen, setAdminDialogIsOpen] = useState(false);

  const isLoggedIn = useSelector((state) => state.isLoggedIn);

  const showHeader = isLoggedIn || /^#\/report\/.*/.test(window.location.hash);

  const handleAdminDialogOpen = () => {
    setAdminDialogIsOpen(true);
  };

  const handleAdminDialogClose = () => {
    setAdminDialogIsOpen(false);
  };

  useEffect(() => {
    store.dispatch(setErrorMessage(null));

    const fetchMe = async () => {
      try {
        const resp = await axios.get(
          `${import.meta.env.VITE_BACKEND_BASE_URL}/v1/me`,
          {
            withCredentials: true,
            headers: {
              Accept: "application/json",
            },
          },
        );

        store.dispatch(setIsAdmin(resp.data.admin || false));
        store.dispatch(setUserFavorites(resp.data.favorites));
      } catch (err) {
        let msg = "Error getting user";
        if (err.response) {
          if (err.response.status === 401) {
            store.dispatch(logOut());
            return;
          }
          msg += `: ${err.response.data}`;
        }
        store.dispatch(setErrorMessage(msg));
      }
    };

    const fetchSettings = async () => {
      try {
        const resp = await axios.get(
          `${import.meta.env.VITE_BACKEND_BASE_URL}/v1/settings`,
          {
            withCredentials: true,
            headers: {
              Accept: "application/json",
            },
          },
        );

        store.dispatch(setSettings(resp.data));
      } catch (err) {
        let msg = "Error getting settings";
        if (err.response) {
          if (err.response.status === 401) {
            store.dispatch(logOut());
            return;
          }
          msg += `: ${err.response.data}`;
        }
        store.dispatch(setErrorMessage(msg));
      }
    };

    const fetchInstaAccounts = async () => {
      try {
        const resp = await axios.get(
          `${import.meta.env.VITE_BACKEND_BASE_URL}/v1/instagram-accounts`,
          {
            withCredentials: true,
            headers: {
              Accept: "application/json",
            },
          },
        );

        store.dispatch(setInstagramAccounts(resp.data));
      } catch (err) {
        let msg = "Error getting instagram accounts";
        if (err.response) {
          if (err.response.status === 401) {
            store.dispatch(logOut());
            return;
          }
          msg += `: ${err.response.data}`;
        }
        store.dispatch(setErrorMessage(msg));
      }
    };

    if (isLoggedIn) {
      fetchMe();
      fetchSettings();
      fetchInstaAccounts();
    }
  }, [isLoggedIn]);

  return (
    <HashRouter>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
        <CssBaseline />

        {showHeader && <Header openFn={handleAdminDialogOpen} />}

        <div className={classes.root}>
          <Routes>
            <Route
              path="/"
              element={
                isLoggedIn ? (
                  <Navigate to="/projects" />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />

            <Route
              path="/projects/*"
              element={
                isLoggedIn ? (
                  <main className={classes.content}>
                    <Routes>
                      <Route path=":tab?" index element={<Projects />} />
                      <Route path="edit/:id" element={<EditProject />} />
                      <Route path="new" element={<CreateProject />} />
                    </Routes>
                  </main>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />

            <Route
              path="/reports/*"
              element={
                <main className={classes.contentConditionalPadding}>
                  <Routes>
                    <Route path=":id" element={<Report />} />
                  </Routes>
                </main>
              }
            />

            <Route
              path="/report/*"
              element={
                <main className={classes.contentConditionalPadding}>
                  <Routes>
                    <Route path=":id" element={<Report />} />
                  </Routes>
                </main>
              }
            />

            <Route
              path="/login/*"
              element={
                <div
                  style={{
                    backgroundColor: "#223647",
                    width: "100%",
                    height: "calc(100vh - 48px)",
                    display: "flex",
                  }}
                >
                  <Routes>
                    <Route index element={<Login />} />
                    <Route path="auth" element={<Auth />} />
                    <Route path="nope" element={<LoginNope />} />
                  </Routes>
                </div>
              }
            />

            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>

        <Footer />

        <Dialog
          open={adminDialogIsOpen}
          closeAfterTransition
          onClose={handleAdminDialogClose}
        >
          <div className={classes.modalPaper}>
            <Admin />
          </div>
        </Dialog>

        <ErrorMessage />
      </LocalizationProvider>
    </HashRouter>
  );
};

export default App;
