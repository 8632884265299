import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Link as MuiLink,
} from "@mui/material";
import moment from "moment";
import numeral from "numeral";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

const ABTest = ({ abTest, classes }) => (
  <Accordion elevation={0} className={classes.storyPanelDFP} square>
    <AccordionSummary
      expandIcon={
        <img
          src="/img/icons/chevron-down.svg"
          alt="Expand"
          style={{ width: "28px", height: "28px" }}
        />
      }
    >
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6" className={classes.teaserAccordionSummaryText}>
          Story A/B Tests
        </Typography>
      </Grid>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container spacing={2}>
        {
          (abTest.optimizationTarget.label = (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <span>{abTest.optimizationTarget.label}</span>
            </Grid>
          ))
        }
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {abTest.isActive ? (
            <span>Test running</span>
          ) : (
            <span>
              Test ended on {moment(abTest.endDate).format("DD.MM.YYYY HH:mm")}
            </span>
          )}
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          {Array.isArray(abTest.variants) &&
            abTest.variants.map((variant) => (
              <Grid
                key={variant.id}
                item
                xs={12}
                sm={12}
                md={12}
                className={classes.dfpCreativeWrapper}
                style={{
                  opacity: variant.isActive === false ? 0.3 : 1,
                }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    style={{ marginTop: "2em" }}
                  >
                    <Typography
                      variant="h6"
                      className={classes.dfpCreativeTitle}
                      style={{ marginBottom: "1em" }}
                    >
                      <span
                        key={variant.id}
                        className={classes.mediaTitleHeader}
                      >
                        {variant.name} {variant.isWinner && <EmojiEventsIcon />}
                      </span>
                    </Typography>
                    {Array.isArray(variant.previewLinks) && (
                      <Typography>
                        Links:{" "}
                        {variant.previewLinks.map((previewLink, i) => (
                          <MuiLink
                            key={i}
                            href={previewLink.url}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                              textDecoration: "underline",
                              fontFamily: "BatonTurbo-Book",
                              color: "#262627",
                              marginLeft: "10px",
                              marginRight: "10px",
                            }}
                          >
                            {previewLink.label}
                          </MuiLink>
                        ))}
                      </Typography>
                    )}
                    <br />
                    <Typography
                      variant="h6"
                      className={
                        abTest.optimizationTarget.metricNames.includes(
                          "pageViews",
                        )
                          ? classes.dfpMetricsKeyBold
                          : classes.dfpMetricsKey
                      }
                    >
                      Pageviews:{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      className={
                        abTest.optimizationTarget.metricNames.includes(
                          "pageViews",
                        )
                          ? classes.dfpMetricsValueBold
                          : classes.dfpMetricsValue
                      }
                    >
                      {numeral(variant.metrics.pageViews).format()}
                    </Typography>
                    <Typography
                      variant="h6"
                      className={
                        abTest.optimizationTarget.metricNames.includes(
                          "avgTimeOnPage",
                        )
                          ? classes.dfpMetricsKeyBold
                          : classes.dfpMetricsKey
                      }
                    >
                      Avg Time on Page:{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      className={
                        abTest.optimizationTarget.metricNames.includes(
                          "avgTimeOnPage",
                        )
                          ? classes.dfpMetricsValueBold
                          : classes.dfpMetricsValue
                      }
                    >
                      {numeral(variant.metrics.avgTimeOnPage).format()}s
                    </Typography>{" "}
                    <Typography
                      variant="h6"
                      className={
                        abTest.optimizationTarget.metricNames.includes(
                          "ctaClicks",
                        )
                          ? classes.dfpMetricsKeyBold
                          : classes.dfpMetricsKey
                      }
                    >
                      CTA Clicks:{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      className={
                        abTest.optimizationTarget.metricNames.includes(
                          "ctaClicks",
                        )
                          ? classes.dfpMetricsValueBold
                          : classes.dfpMetricsValue
                      }
                    >
                      {numeral(variant.metrics.ctaClicks).format()}
                    </Typography>{" "}
                    <Typography
                      variant="h6"
                      className={
                        abTest.optimizationTarget.metricNames.includes(
                          "outboundClicks",
                        )
                          ? classes.dfpMetricsKeyBold
                          : classes.dfpMetricsKey
                      }
                    >
                      Outbound Clicks:{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      className={
                        abTest.optimizationTarget.metricNames.includes(
                          "outboundClicks",
                        )
                          ? classes.dfpMetricsValueBold
                          : classes.dfpMetricsValue
                      }
                    >
                      {numeral(variant.metrics.outboundClicks).format()}
                    </Typography>
                    <Typography
                      variant="h6"
                      className={
                        abTest.optimizationTarget.metricNames.includes(
                          "avgScrollDepthPercent",
                        )
                          ? classes.dfpMetricsKeyBold
                          : classes.dfpMetricsKey
                      }
                    >
                      Avg Scroll Depth:{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      className={
                        abTest.optimizationTarget.metricNames.includes(
                          "avgScrollDepthPercent",
                        )
                          ? classes.dfpMetricsValueBold
                          : classes.dfpMetricsValue
                      }
                    >
                      {numeral(variant.metrics.avgScrollDepthPercent).format()}%
                    </Typography>{" "}
                    <Typography
                      variant="h6"
                      className={
                        abTest.optimizationTarget.metricNames.includes("shares")
                          ? classes.dfpMetricsKeyBold
                          : classes.dfpMetricsKey
                      }
                    >
                      Shares:{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      className={
                        abTest.optimizationTarget.metricNames.includes("shares")
                          ? classes.dfpMetricsValueBold
                          : classes.dfpMetricsValue
                      }
                    >
                      {numeral(variant.metrics.shares).format()}
                    </Typography>{" "}
                  </Grid>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </AccordionDetails>
  </Accordion>
);

export default ABTest;
