import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logOut, setErrorMessage } from "../../redux/actions";
import Project from "./Project";
import ProjectsSkeleton from "./ProjectsSkeleton";
import { Button, Grid, TextField } from "@mui/material";

export const TABS = {
  FAV: 0,
  ME: 1,
  ALL: 2,
  SEARCH: 3,
  ACTIVE: 4,
};

export const FavoriteTab = ({ tabIndex, onProjectEdit }) => {
  const userFavorites = useSelector((state) => state.favorites);
  const [projects, loading] = useAPI("me/favorites", tabIndex === TABS.FAV, [
    userFavorites,
  ]);

  return (
    <TabPanel value={tabIndex} index={TABS.FAV}>
      <ProjectsList
        projects={projects}
        loading={loading}
        onEdit={onProjectEdit}
      />
    </TabPanel>
  );
};

export const MyReportsTab = ({ tabIndex, onProjectEdit }) => {
  const [projects, loading] = useAPI("me/projects", tabIndex === TABS.ME);

  return (
    <TabPanel value={tabIndex} index={TABS.ME}>
      <ProjectsList
        projects={projects}
        loading={loading}
        onEdit={onProjectEdit}
      />
    </TabPanel>
  );
};

export const AllTab = ({ tabIndex, onProjectEdit }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialPage = parseInt(searchParams.get("page")) || 1;
  const [page, setPage] = useState(initialPage);
  const [projects, loading] = useAPI(
    `projects?page=${page}`,
    tabIndex === TABS.ALL,
    [page],
  );

  const handlePageChange = (event) => {
    const value = parseInt(event.target.value);
    if (value > 0) {
      console.log(value);
      updatePage(value);
    }
  };

  const updatePage = (value) => {
    setPage(value);
    setSearchParams({ page: value.toString() });
  };

  return (
    <TabPanel value={tabIndex} index={TABS.ALL}>
      <ProjectsList
        projects={projects}
        loading={loading}
        onEdit={onProjectEdit}
      />
      <Grid container spacing={2} style={{ marginTop: "2em" }}>
        <Grid item xs={12} sm={4}>
          <Button
            variant="outlined"
            fullWidth
            size="large"
            disabled={page <= 1}
            onClick={() => updatePage(page - 1)}
          >
            &lt; Previous
          </Button>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Page"
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            value={page}
            onChange={handlePageChange}
            inputProps={{ min: 1 }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            variant="outlined"
            size="large"
            fullWidth
            onClick={() => updatePage(page + 1)}
          >
            Next &gt;
          </Button>
        </Grid>
      </Grid>
    </TabPanel>
  );
};

export const SearchTab = ({ tabIndex, onProjectEdit }) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParams, _] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleSearch = async () => {
      const term = searchParams.get("term");
      if (!term) return;
      try {
        setLoading(true);
        navigate(`/projects/search?term=${term}`);
        const response = await axios.get(
          `${import.meta.env.VITE_BACKEND_BASE_URL}/v1/projects`,
          {
            withCredentials: true,
            headers: { Accept: "application/json" },
            params: { search: term },
          },
        );
        setProjects(response.data);
      } catch (err) {
        if (err.response?.status === 401) {
          dispatch(logOut());
          navigate("/login");
          return;
        }
        dispatch(
          setErrorMessage(
            `Error searching: ${err.response?.data || err.message}`,
          ),
        );
      } finally {
        setLoading(false);
      }
    };
    handleSearch();
  }, [searchParams]);

  return (
    <TabPanel value={tabIndex} index={TABS.SEARCH}>
      <ProjectsList
        projects={projects}
        loading={loading}
        onEdit={onProjectEdit}
      />
    </TabPanel>
  );
};

export const ActiveTab = ({ tabIndex, onProjectEdit }) => {
  const [projects, loading] = useAPI(
    "projects/active",
    tabIndex === TABS.ACTIVE,
  );

  return (
    <TabPanel value={tabIndex} index={TABS.ACTIVE}>
      <ProjectsList
        projects={projects}
        loading={loading}
        onEdit={onProjectEdit}
      />
    </TabPanel>
  );
};

const TabPanel = ({ value, index, children }) => (
  <div role="tabpanel" hidden={value !== index}>
    {value === index && (
      <Grid container spacing={2}>
        {children}
      </Grid>
    )}
  </div>
);

const ProjectsList = ({ projects, loading, onEdit }) => {
  if (loading) return <ProjectsSkeleton />;

  if (!projects.length) {
    return (
      <Grid item xs={12}>
        <div style={{ paddingTop: 18, textAlign: "center" }}>No reports</div>
      </Grid>
    );
  }

  return projects.map((project) => (
    <Project key={project._id} project={project} onEdit={onEdit} />
  ));
};

const useAPI = (endpoint, shouldFetch = false, dependencies = []) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!shouldFetch) return;
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${import.meta.env.VITE_BACKEND_BASE_URL}/v1/${endpoint}`,
          {
            withCredentials: true,
            headers: { Accept: "application/json" },
          },
        );
        // TODO: this is only necessary for pagination
        window.scrollTo(0, 0);
        setData(response.data);
      } catch (err) {
        if (err.response?.status === 401) {
          dispatch(logOut());
          navigate("/login");
          return;
        }
        dispatch(
          setErrorMessage(`Error: ${err.response?.data || err.message}`),
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [...dependencies, navigate, dispatch]);

  return [data, loading, setData];
};
