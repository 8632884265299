import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Tabs, Tab, Fab, Fade, Dialog, Box } from "@mui/material";
import { useStyles } from "./ProjectsStyles";
import Search from "../Search";
import EditProject from "../EditProject";
import {
  TABS,
  FavoriteTab,
  MyReportsTab,
  AllTab,
  SearchTab,
  ActiveTab,
} from "./Tabs";

const Projects = () => {
  const { tab } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();

  const [editProject, setEditProject] = useState(null);
  const initialTab = tab ? TABS[tab.toUpperCase()] || TABS.FAV : TABS.FAV;
  const [tabIndex, setTabIndex] = useState(initialTab);

  useEffect(() => {
    if (tab && TABS[tab.toUpperCase()]) {
      setTabIndex(TABS[tab.toUpperCase()]);
    }
  }, [tab]);

  const handleTabChange = (newTab) => {
    setTabIndex(newTab);
    const tabKey = Object.keys(TABS).find((key) => TABS[key] === newTab);
    navigate(`/projects/${tabKey.toLowerCase()}`);
  };

  return (
    <Grid container spacing={2} className={classes.projectsGridContainer}>
      <Dialog
        open={Boolean(editProject)}
        closeAfterTransition
        onClose={() => setEditProject(null)}
        maxWidth="md"
      >
        <Fade in={Boolean(editProject)}>
          <div className={classes.modalPaper}>
            <EditProject
              project={editProject}
              handleCloseDialog={() => setEditProject(null)}
            />
          </div>
        </Fade>
      </Dialog>

      <Fab
        className={classes.fabAddProject}
        size="large"
        onClick={() => navigate("/projects/new")}
      >
        <Box style={{ display: "flex" }}>
          <img
            src="/img/icons/plus.svg"
            alt="Create project"
            style={{ width: "35px", height: "35px" }}
          />
        </Box>
      </Fab>

      <Grid item xs={12}>
        <Search />
      </Grid>

      <Grid item xs={12}>
        <Tabs
          value={tabIndex}
          onChange={(_, index) => handleTabChange(index)}
          centered
          className={classes.tabContainerWrapper}
          TabIndicatorProps={{
            style: { backgroundColor: "#223647", height: "4px" },
          }}
          aria-label="projects tabs"
        >
          {["Favorites", "My reports", "All", "Search Results", "Active"].map(
            (label, index) => (
              <Tab
                key={label}
                label={label}
                className={
                  tabIndex === index ? classes.tabActive : classes.tabDefault
                }
                sx={{ textTransform: "none" }}
                id={`simple-tab-${index}`}
                aria-controls={`simple-tabpanel-${index}`}
              />
            ),
          )}
        </Tabs>
      </Grid>

      <Grid item xs={12}>
        <FavoriteTab tabIndex={tabIndex} onProjectEdit={setEditProject} />
        <MyReportsTab tabIndex={tabIndex} onProjectEdit={setEditProject} />
        <AllTab tabIndex={tabIndex} onProjectEdit={setEditProject} />
        <SearchTab tabIndex={tabIndex} onProjectEdit={setEditProject} />
        <ActiveTab tabIndex={tabIndex} onProjectEdit={setEditProject} />
      </Grid>
    </Grid>
  );
};

export default Projects;
