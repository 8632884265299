import { Grid } from "@mui/material";
import Story from "./Story";

const extractDailyDealMetadata = (story) => {
  const metadata = {
    title: "tbd, creative fallback",
    image: "n/a",
    description: "n/a",
  };

  if (story.name) {
    metadata.title = story.name;
  }

  if (Array.isArray(story.images) && story.images.length > 0) {
    // not sure if .image is the right key
    // check if actual fields exists
    metadata.image = {
      orig_src: story.images[0],
      src: story.images[0],
    };
  } else {
    // fall back on creative data
    if (Array.isArray(story.creatives) && story.creatives.length > 0) {
      // pick the first creative (alternative: random?)
      const selectedCreative = story.creatives[0];
      metadata.image = {
        orig_src: selectedCreative.image,
        src: selectedCreative.image,
      };
    }
  }

  if (story.description) {
    // not sure if .description is the right key
    // check if actual fields exists
    metadata.description = story.description;
  } else {
    // fall back on creative data
    if (Array.isArray(story.creatives) && story.creatives.length > 0) {
      // pick the first creative (alternative: random?)
      const selectedCreative = story.creatives[0];
      metadata.description = selectedCreative.lead;
    }
  }

  // append links

  return metadata;
};

const Stories = ({
  stories,
  classes,
  kpis,
  projectData,
  abTests,
  gaSummarized,
}) => {
  // TODO: change input
  const { isDailyDeal } = projectData;
  const idsToDisplay = Array.isArray(kpis)
    ? kpis.map((e) => String(e.key))
    : [];

  const findAbTest = (storyID, locale) => {
    let abTest = Array.isArray(abTests)
      ? abTests.find((storyAbTest) => storyAbTest.storyID === storyID)
      : null;

    if (!abTest) {
      return;
    }

    let variantsByLanguage = [];
    if (abTest?.variants) {
      variantsByLanguage = abTest.variants[locale];
    }

    return { ...abTest, variants: variantsByLanguage };
  };

  const findStoryInGaSummarized = (storyID, locale) => {
    const metrics = gaSummarized.metrics;
    if (!metrics || !metrics.stories) {
      return undefined;
    }

    const storyByID = metrics.stories[storyID];

    if (!storyByID || !(locale in storyByID)) {
      return undefined;
    }

    return storyByID[locale];
  };

  // generate story objects for every language
  const languageStories = (stories) => {
    const storiesByLanguage = [];

    if (Array.isArray(stories)) {
      stories.forEach((story) => {
        if (Array.isArray(story.languages)) {
          story.languages.forEach((lang) => {
            let abTest = findAbTest(story.id, lang.locale);
            let gaSummarized = findStoryInGaSummarized(story.id, lang.locale);
            // extract metadata for dailyDeal
            const extractedDailyDealMetadata = isDailyDeal
              ? extractDailyDealMetadata(story, lang.locale)
              : null;

            const og_image =
              story.metadata && story.metadata.og_image
                ? story.metadata.og_image[lang.locale]
                : isDailyDeal && extractedDailyDealMetadata
                  ? extractedDailyDealMetadata.image
                  : null;

            const metadataLanguage = {
              og_title: {
                [lang.locale]:
                  story.metadata && story.metadata.og_title
                    ? story.metadata.og_title[lang.locale]
                    : isDailyDeal && extractedDailyDealMetadata
                      ? extractedDailyDealMetadata.title
                      : null,
              },
              og_description: {
                [lang.locale]:
                  story.metadata && story.metadata.og_description
                    ? story.metadata.og_description[lang.locale]
                    : projectData.isDailyDeal && extractedDailyDealMetadata
                      ? extractedDailyDealMetadata.description
                      : null,
              },
              og_image: {
                [lang.locale]: { ...og_image },
              },
            };

            const linksLanguage = {};

            // prevent error in dailyDeal case
            if (story.links) {
              Object.keys(story.links).forEach((p) => {
                if (story.links[p][lang.locale]) {
                  linksLanguage[p] = {
                    [lang.locale]: story.links[p][lang.locale],
                  };
                }
              });
            }

            const storyByLanguage = {
              ...story,
              id: `${story.id}-${lang.locale}`,
              name:
                story.metadata &&
                story.metadata.og_title &&
                story.metadata.og_title[lang.locale]
                  ? story.metadata.og_title[lang.locale]
                  : metadataLanguage &&
                      metadataLanguage.og_title &&
                      metadataLanguage.og_title[lang.locale]
                    ? metadataLanguage.og_title[lang.locale]
                    : null,
              metadata: metadataLanguage,
              language: lang.locale,
              // creatives with DFP needs to be filtered by language
              creativesWithDFP: Array.isArray(story.creativesWithDFP)
                ? story.creativesWithDFP.filter(
                    (c) => c.language_code === lang.locale,
                  )
                : [],
              languages: Array.isArray(story.languages)
                ? story.languages.filter((c) => c.locale === lang.locale)
                : [],
              links: linksLanguage,
              KPIs: kpis.find((kpi) => {
                return kpi.key === `${story.id}-${lang.locale}`;
              }),
              abTest,
              gaSummarized,
            };

            storiesByLanguage.push(storyByLanguage);
          });
        }
      });
    }

    const storiesFinal =
      storiesByLanguage.length > 0 ? storiesByLanguage : stories;
    return storiesFinal;
  };

  const storiesByLanguage = languageStories(stories);

  return (
    <Grid container spacing={2} className={classes.centeredTabs}>
      {storiesByLanguage.map((story, i) => {
        if (!idsToDisplay.includes(story.id) && !projectData.isDailyDeal) {
          return null;
        }

        return (
          <div
            key={story.id}
            style={{ width: "100%", marginTop: "8px", marginBottom: "8px" }}
          >
            <Story
              className={classes.root}
              classes={classes}
              story={story}
              index={i}
              kpis={kpis}
            />
          </div>
        );
      })}
    </Grid>
  );
};

export default Stories;
