export const setIsAdmin = (isAdmin) => ({
  type: "SET_IS_ADMIN",
  isAdmin,
});

export const logIn = (isLoggedIn) => ({
  type: "LOG_IN",
  isLoggedIn,
});

export const setUserFavorites = (favorites) => ({
  type: "SET_FAVORITES",
  favorites,
});

export const setSettings = (settings) => ({
  type: "SET_SETTINGS",
  settings,
});

export const setInstagramAccounts = (accounts) => ({
  type: "SET_INSTAGRAM_ACCOUNTS",
  accounts,
});

export const setErrorMessage = (msg) => ({
  type: "SET_ERROR_MESSAGE",
  msg,
});

export const logOut = () => ({
  type: "LOG_OUT",
});
