import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

export const useStyles = makeStyles((theme) =>
  createStyles({
    overrides: {
      MuiInputBase: {
        root: {
          boxShadow: "none",
          borderRadius: "0px",
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
          backgroundColor: "#ffffff",
          border: "1px solid green",
        },
        formControl: {
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
        },
        input: {
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
        },
      },
    },
    root: {
      padding: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
    },
    paperProject: {
      boxShadow: "0 1px 3px 0 rgba(21, 27, 32, 0.15)",
      backgroundColor: "#ffffff",
      padding: "8px 13px 8px 13px",
      "& :hover": {
        backgroundColor: "#f7f7fa",
      },
    },
    paperProjectWrapper: {
      cursor: "pointer",
      "& :hover": {
        backgroundColor: "#f7f7fa",
      },
    },
    tabContainerWrapper: {
      justifyContent: "center",
      marginBottom: "50px",
      minHeight: "44px",
      borderBottom: "2px solid rgba(72, 72, 72, 0.16)",
      [theme.breakpoints.down("lg")]: {
        marginBottom: "0px",
      },
    },
    underline: {
      "&&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    fabIcon: {
      height: 50,
      width: 50,
    },
    fabIconNewProject: {},
    fabAddProject: {
      position: "fixed",
      bottom: theme.spacing(3),
      right: theme.spacing(3),
      zIndex: 100,
      backgroundColor: "white",
      fontSize: "",
      boxShadow:
        "0 2px 8px 0 rgba(21, 27, 32, 0.26), 0 1px 3px 0 rgba(21, 27, 32, 0.15)",

      [theme.breakpoints.up("lg")]: {
        top: "140px",
        right: "calc(50% - 440px)",
      },
    },
    modalPaper: {
      backgroundColor: "#f7f7fa",
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      maxWidth: "800px",
      outline: "none",
    },
    iconItem: {
      display: "flex",
      justifyContent: "flex-end",
    },
    projectsGridContainer: {
      [theme.breakpoints.up("lg")]: {
        paddingLeft: "10%",
        paddingRight: "10%",
      },
    },
    tabActive: {
      paddingBottom: "0px",
      minHeight: "10px",
      fontWeight: "200",
      fontSize: "16px",
      color: "#272726",
    },
    tabDefault: {
      paddingBottom: "0px",
      minHeight: "10px",
      fontWeight: "200",
      fontSize: "16px",
      color: "#5e686c",
    },
  }),
);
