import { Grid, Button, Box, Link } from "@mui/material";

const Login = () => (
  <div
    style={{
      padding: "15px",
      maxWidth: "694px",
      margin: "auto",
      display: "flex",
      color: "#ffffff",
      fontSize: "22px",
    }}
  >
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        sx={{
          marginBottom: {
            xs: "50px",
            md: "114px",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              height: {
                xs: "68px",
                md: "93.6px",
              },
              width: {
                xs: "68px",
                md: "93.6px",
              },
              marginRight: "40px",
            }}
          >
            <Box
              component="img"
              src="/img/20min_de_vector_negative.svg"
              alt="Tamedia Logo"
              sx={{
                height: {
                  xs: "68px",
                  md: "93.6px",
                },
                width: {
                  xs: "68px",
                  md: "93.6px",
                },
              }}
            />
          </Box>
          <Box
            sx={{
              height: {
                xs: "68px",
                md: "93.6px",
              },
              width: {
                xs: "68px",
                md: "93.6px",
              },
              marginRight: 0,
            }}
          >
            <Box
              component="img"
              src="/img/Tamedia-Logo.svg"
              alt="Tamedia Logo"
              sx={{
                height: {
                  xs: "68px",
                  md: "93.6px",
                },
                width: {
                  xs: "68px",
                  md: "93.6px",
                },
              }}
            />
          </Box>
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        sx={{
          marginBottom: {
            xs: "50px",
            md: "114px",
          },
          padding: {
            xs: "16px",
            md: "inherit",
          },
          fontSize: {
            xs: "16px",
            md: "inherit",
          },
          textAlign: "center",
          fontWeight: "200",
          "&&&&": {
            padding: {
              xs: "20px",
              md: "inherit",
            },
          },
        }}
      >
        Commercial Publishing TX Group creates compelling advertisements. We
        produce cross-media content, adapted to every title, optimized for
        online and print. Do you want advertising that tells real stories and
        thus creates customer loyalty? Commercial publishing delivers it.
        <br />
        <br />
        We offer you excellence in text, image and graphics, reach and
        performance.
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box>
          <Button
            variant="outlined"
            component="a"
            href={`${import.meta.env.VITE_BACKEND_BASE_URL}/auth/google`}
            style={{
              backgroundColor: "white",
              width: "134px",
              height: "63px",
              textTransform: "none",
              fontSize: "19px",
              "&:hover": {
                backgroundColor: "#f7f7fa",
              },
            }}
          >
            Login
          </Button>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box>
          <Link
            variant="body1"
            color="inherit"
            href="mailto:tech-cp@20minuten.ch?subject=Login%20Request%20(Reporting-Tool)"
            target="_blank"
            rel="noopener"
          >
            Request login here
          </Link>
        </Box>
      </Grid>
    </Grid>
  </div>
);

export default Login;
