import { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Paper, Grid, TextField, IconButton, Box } from "@mui/material";

const Search = () => {
  let [searchParams, _] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState(searchParams.get("term") || "");

  const navigate = useNavigate();

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchButtonClick = () => {
    // TODO: this is not the responsibility of this component
    navigate(`/projects/search?term=${searchTerm}`);
  };

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Paper
        component="form"
        square
        sx={{
          marginTop: {
            xs: "10px",
            xl: "50px",
          },
          marginBottom: {
            xs: "10px",
            xl: "30px",
          },
          boxShadow: "0 1px 3px 0 rgba(21, 27, 32, 0.15)",
        }}
      >
        <div
          style={{
            display: "flex",
            padding: "5px",
          }}
        >
          <TextField
            placeholder="Search by order number, customer or campaign"
            value={searchTerm}
            fullWidth
            style={{
              borderBottom: "none",
              paddingTop: "6px",
              paddingBottom: "6px",
              paddingLeft: "4px",
              border: "0px solid white",
              boxShadow: "none",
              fontSize: "18px",
              "& input": {
                boxShadow: "none",
                width: "100%",
                fontSize: "18px",
                paddingLeft: "24px",
              },
            }}
            InputProps={{
              style: {
                borderBottom: "none",
                paddingTop: "6px",
                paddingBottom: "6px",
                paddingLeft: "4px",
                border: "0px solid white",
                boxShadow: "none",
                fontSize: "18px",
                "& input": {
                  boxShadow: "none",
                  width: "100%",
                  fontSize: "18px",
                  paddingLeft: "24px",
                },
              },
            }}
            onChange={handleSearchTermChange}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                handleSearchButtonClick();
              }
            }}
          />

          <IconButton
            size="large"
            style={{ width: "65px", height: "65px" }}
            aria-label="search"
            onClick={handleSearchButtonClick}
          >
            <Box style={{ display: "flex" }}>
              <img
                src="/img/icons/Search.svg"
                alt="Search"
                style={{ width: "28px", height: "28px" }}
              />
            </Box>
          </IconButton>
        </div>
      </Paper>
    </Grid>
  );
};

export default Search;
