import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import {
  Paper,
  Grid,
  Typography,
  Button,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  Box,
  Fab,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import store from "../redux/store";
import { logOut, setErrorMessage } from "../redux/actions/index";
import LoadingSpinner from "./LoadingSpinner";
import SocialLinks from "./SocialLinks";
import URLField from "./URLField";
import { toDate } from "date-fns";

const URLFields = ({ urls, removeURL, editURL, label }) =>
  urls.map((e, i) => (
    <Grid key={i} item xs={12} sm={12} md={12}>
      <FormControl fullWidth variant="outlined">
        <InputLabel
          htmlFor="outlined-adornment-password"
          style={{
            color: "#aeb5bd",
          }}
        >
          {label} {i + 1}
        </InputLabel>

        <URLField index={i} removeURL={removeURL} url={e} editURL={editURL} />
      </FormControl>
    </Grid>
  ));

const newEmptyProject = () => ({
  abnumber: "",
  projectType: "abnumberChoice",
  name: "",
  description: "",
  client: "",
  daterangeFrom: new Date(),
  daterangeTo: new Date(),
  urls: [],
  socialLinks: [],
  isLinkoutCampaign: false,
  isContentHub: false,
});

const CreateProject = () => {
  const navigate = useNavigate();
  const isAdmin = useSelector((state) => state.isAdmin);
  const settings = useSelector((state) => state.settings);
  const instagramAccounts = useSelector((state) => state.instagramAccounts);
  const [projectData, setProjectData] = useState(newEmptyProject());
  const [projectType, setProjectType] = useState("default");
  const [includesSocialMediaPosts, setIncludesSocialMediaPosts] =
    useState(false);
  const [includesLongforms, setIncludesLongforms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    const edit = {};

    // trim whitespace from abnumber
    if (event.target.name === "abnumber") {
      const trimmed = event.target.value.replace(" ", "");
      edit[event.target.name] = trimmed;
    } else {
      edit[event.target.name] = event.target.value;
    }
    setProjectData({ ...projectData, ...edit });
  };

  const handleDateChange = (fieldName) => (date) => {
    if (date !== "Invalid Date") {
      const edit = {};
      edit[fieldName] = date;
      setProjectData({ ...projectData, ...edit });
    }
  };

  const handleIsLinkoutCampaignChange = (value) => {
    const checkValue = value.target.checked;
    const edit = { isLinkoutCampaign: checkValue };
    setProjectData({ ...projectData, ...edit });
  };

  const handleIsContentHubChange = (value) => {
    const checkValue = value.target.checked;
    const edit = { isContentHub: checkValue };
    setProjectData({ ...projectData, ...edit });
  };

  const handleIncludesSocialMediaPostsChange = (value) => {
    const checked = value.target.checked;
    setIncludesSocialMediaPosts(checked);
    const socialLinks = checked
      ? [
          {
            socialLink: "",
            targetReachId: "",
            instagramAccount: null,
          },
        ]
      : [];
    const edit = { socialLinks };
    setProjectData({ ...projectData, ...edit });
  };

  const handleIncludesLongformsChange = (value) => {
    const checked = value.target.checked;
    setIncludesLongforms(checked);
    const longforms = checked ? [""] : [];
    const edit = { longforms };
    setProjectData({ ...projectData, ...edit });
  };

  const handleDailyDeal = (value) => {
    const checkValue = value.target.checked;
    const edit = { isDailyDeal: checkValue };
    setProjectData({ ...projectData, ...edit });
  };

  const addURL = () => {
    const edit = { urls: projectData.urls || [] };
    edit.urls.push("");
    setProjectData({ ...projectData, ...edit });
  };

  const removeURL = (key) => () => {
    const edit = { urls: projectData.urls || [] };
    edit.urls.splice(key, 1);
    setProjectData({ ...projectData, ...edit });
  };

  const editURL = (key) => (value) => {
    const edit = { urls: projectData.urls || [] };
    edit.urls[key] = value;
    setProjectData({ ...projectData, ...edit });
  };

  const addSocialLink = () => {
    const edit = { socialLinks: projectData.socialLinks || [] };
    edit.socialLinks.push({
      socialLink: "",
      targetReachId: "",
      instagramAccount: null,
    });
    setProjectData({ ...projectData, ...edit });
  };

  const removeSocialLink = (key) => () => {
    const edit = { socialLinks: projectData.socialLinks || [] };
    edit.socialLinks.splice(key, 1);
    setProjectData({ ...projectData, ...edit });
  };

  const editSocialLink = (key) => (value) => {
    const edit = { socialLinks: projectData.socialLinks || [] };
    edit.socialLinks[key].socialLink = value;
    setProjectData({ ...projectData, ...edit });
  };

  const editTargetReach = (key) => (value) => {
    const edit = { socialLinks: projectData.socialLinks || [] };
    edit.socialLinks[key].targetReachId = value || null;
    setProjectData({ ...projectData, ...edit });
  };

  const editInstaAccount = (key) => (value) => {
    const edit = { socialLinks: projectData.socialLinks || [] };
    edit.socialLinks[key].instagramAccount = value || null;
    setProjectData({ ...projectData, ...edit });
  };

  const addLongform = () => {
    const edit = { longforms: projectData.longforms || [] };
    edit.longforms.push("");
    setProjectData({ ...projectData, ...edit });
  };

  const removeLongform = (key) => () => {
    const edit = { longforms: projectData.longforms || [] };
    edit.longforms.splice(key, 1);
    setProjectData({ ...projectData, ...edit });
  };

  const editLongform = (key) => (value) => {
    const edit = { longforms: projectData.longforms || [] };
    edit.longforms[key] = value;
    setProjectData({ ...projectData, ...edit });
  };

  const saveProject = async () => {
    store.dispatch(setErrorMessage(null));
    setIsLoading(true);

    const data = JSON.parse(JSON.stringify(projectData));

    data.daterangeFrom = moment(projectData.daterangeFrom).format("YYYY-MM-DD");
    data.daterangeTo = moment(projectData.daterangeTo).format("YYYY-MM-DD");

    // check if storyLinkChoice, remove SEO slug if it is
    if (projectData && projectData.projectType === "storyLinkChoice") {
      const validURLPattern = /.*stories\/\d{1,8}/i;
      let urlsNoSEO;
      if (Array.isArray(projectData.urls)) {
        urlsNoSEO = projectData.urls.map((e) => {
          const match = e.match(validURLPattern);

          if (Array.isArray(match)) {
            return match[0];
          }
          return e;
        });
      }
      data.urls = urlsNoSEO;
    }

    if (projectData && projectData.projectType === "soMeAdChoice") {
      delete data.abnumber;
    }

    try {
      const resp = await axios.post(
        `${import.meta.env.VITE_BACKEND_BASE_URL}/v1/projects`,
        data,
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
          },
        },
      );

      navigate(`/reports/${resp.data._id}`);
    } catch (err) {
      let msg = "Error creating report";
      if (err.response) {
        if (err.response.status === 401) {
          store.dispatch(logOut());
          navigate("/login");
          return;
        }
        msg += `: ${err.response.data}`;
      }
      store.dispatch(setErrorMessage(msg));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let edit = {};
    switch (projectType) {
      case "customURL":
        edit = { projectType: "storyLinkChoice" };
        break;
      case "soMeAd":
        edit = { projectType: "soMeAdChoice" };
        break;
      default:
        edit = { projectType: "abnumberChoice" };
    }
    setProjectData({ ...projectData, ...edit });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectType]);

  const changeType = (newType) => {
    setProjectType(newType);
    if (
      projectData &&
      Array.isArray(projectData.urls) &&
      projectData.urls.length === 0
    ) {
      addURL();
    }
    if (
      projectData &&
      Array.isArray(projectData.socialLinks) &&
      projectData.socialLinks.length === 0
    ) {
      addSocialLink();
    }
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        paddingLeft: { lg: "10%" },
        paddingRight: { lg: "10%" },
      }}
    >
      <Fab
        color="secondary"
        aria-label="back"
        component={Link}
        to="/"
        sx={{
          position: "fixed",
          top: { lg: "180px" },
          bottom: 24,
          left: { xs: 24, lg: "calc(50% - 500px)" },
          zIndex: 100,
          backgroundColor: "white",
          fontSize: "",
          color: "#223647",
          boxShadow:
            "0 2px 8px 0 rgba(21, 27, 32, 0.26), 0 1px 3px 0 rgba(21, 27, 32, 0.15)",
          "&:hover": {
            backgroundColor: "#f7f7fa",
          },
        }}
      >
        <Box style={{ display: "flex" }}>
          <img
            src="/img/icons/chevron-left.svg"
            alt="Back"
            style={{ width: "28px" }}
          />
        </Box>
      </Fab>
      <Grid item xs={12} sm={12} md={12}>
        <Paper
          elevation={0}
          sx={{
            p: 2,
            pb: 0,
            px: { sm: 3 },
            backgroundColor: "#f7f7fa",
          }}
        >
          <Typography
            variant="h6"
            style={{ fontWeight: "200", fontSize: "26px", textAlign: "center" }}
          >
            Create {projectType === "default" && ""}
            {projectType === "customURL" && "Custom URL"}
            {projectType === "soMeAd" && "SoMeAd Only"} Report
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Paper
          elevation={0}
          sx={{
            p: 2,
            pb: 0,
            px: { sm: 3 },
            backgroundColor: "#f7f7fa",
          }}
        >
          <Grid container spacing={2}>
            {projectType === "default" && (
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  name="abnumber"
                  variant="outlined"
                  label="Order number"
                  value={projectData.abnumber || ""}
                  fullWidth
                  onChange={handleChange}
                  InputLabelProps={{
                    style: {
                      color: "#aeb5bd",
                    },
                  }}
                />
              </Grid>
            )}

            {projectType !== "default" && (
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  label="Customer"
                  name="client"
                  variant="outlined"
                  value={projectData.client || ""}
                  fullWidth
                  onChange={handleChange}
                  InputLabelProps={{
                    style: {
                      color: "#aeb5bd",
                    },
                  }}
                />
              </Grid>
            )}

            {projectType !== "default" && (
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  name="name"
                  variant="outlined"
                  label="Campaign"
                  value={projectData.name || ""}
                  fullWidth
                  onChange={handleChange}
                  InputLabelProps={{
                    style: {
                      color: "#aeb5bd",
                    },
                  }}
                />
              </Grid>
            )}

            <Grid item xs={12} sm={12} md={6}>
              <DatePicker
                label="Start date"
                value={
                  projectData
                    ? toDate(projectData.daterangeFrom)
                    : toDate(new Date())
                }
                onChange={handleDateChange("daterangeFrom")}
                slotProps={{
                  textField: {
                    fullWidth: true,
                  },
                }}
                slots={{
                  openPickerIcon: () => (
                    <img
                      src="/img/icons/calendar-grey.svg"
                      alt="Pick from date"
                      style={{ width: "28px", height: "28px" }}
                    />
                  ),
                }}
                minDate={
                  isAdmin ? new Date("2020-01-01") : new Date("2021-01-01")
                }
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <DatePicker
                label="End date"
                value={
                  projectData
                    ? toDate(projectData.daterangeTo)
                    : toDate(new Date())
                }
                onChange={handleDateChange("daterangeTo")}
                slotProps={{
                  textField: {
                    fullWidth: true,
                  },
                }}
                slots={{
                  openPickerIcon: () => (
                    <img
                      src="/img/icons/calendar-grey.svg"
                      alt="Pick from date"
                      style={{ width: "28px", height: "28px" }}
                    />
                  ),
                }}
                minDate={
                  isAdmin ? new Date("2020-01-01") : new Date("2021-01-01")
                }
              />
            </Grid>

            {projectType === "default" && (
              <>
                <Grid item xs={12} sm={12} md={6}>
                  <FormControlLabel
                    label="Linkout Campaign"
                    control={
                      <Checkbox
                        checked={projectData.isLinkoutCampaign}
                        onChange={handleIsLinkoutCampaignChange}
                      />
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <FormControlLabel
                    label="Content Hub"
                    control={
                      <Checkbox
                        checked={projectData.isContentHub}
                        onChange={handleIsContentHubChange}
                      />
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <FormControlLabel
                    label="Includes Social Media Posts"
                    control={
                      <Checkbox
                        checked={includesSocialMediaPosts}
                        onChange={handleIncludesSocialMediaPostsChange}
                      />
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <FormControlLabel
                    label="Includes Longforms"
                    control={
                      <Checkbox
                        checked={includesLongforms}
                        onChange={handleIncludesLongformsChange}
                      />
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <FormControlLabel
                    value="isDailyDeal"
                    label="Daily Deal"
                    labelPlacement="end"
                    control={<Checkbox onChange={handleDailyDeal} />}
                  />
                </Grid>
              </>
            )}

            {projectType === "customURL" && (
              <>
                <URLFields
                  urls={projectData.urls}
                  removeURL={removeURL}
                  editURL={editURL}
                  label="Story Link"
                />
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <IconButton onClick={addURL} size="large">
                    <Box style={{ display: "flex" }}>
                      <img
                        src="/img/icons/plus-circle.svg"
                        alt="Add URL"
                        style={{ width: "23.6px", height: "23.6px" }}
                      />
                    </Box>
                  </IconButton>
                </Grid>
              </>
            )}

            {(projectType === "soMeAd" ||
              (projectType === "default" && includesSocialMediaPosts)) && (
              <>
                <SocialLinks
                  socialLinks={projectData.socialLinks}
                  removeSocialLink={removeSocialLink}
                  editSocialLink={editSocialLink}
                  label="Social Media Post"
                  targetOptions={settings.soLinkTargetReachConfigs}
                  editTargetReach={editTargetReach}
                  instaOptions={instagramAccounts}
                  editInstaAccount={editInstaAccount}
                />
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <IconButton onClick={addSocialLink} size="large">
                    <Box style={{ display: "flex" }}>
                      <img
                        src="/img/icons/plus-circle.svg"
                        alt="Add social media link"
                        style={{ width: "23.6px", height: "23.6px" }}
                      />
                    </Box>
                  </IconButton>
                </Grid>
              </>
            )}

            {projectType === "default" && includesLongforms && (
              <>
                <URLFields
                  urls={projectData.longforms}
                  removeURL={removeLongform}
                  editURL={editLongform}
                  label="Longform URL"
                />
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <IconButton onClick={addLongform} size="large">
                    <Box style={{ display: "flex" }}>
                      <img
                        src="/img/icons/plus-circle.svg"
                        alt="Add social media link"
                        style={{ width: "23.6px", height: "23.6px" }}
                      />
                    </Box>
                  </IconButton>
                </Grid>
              </>
            )}

            <LoadingSpinner isLoading={isLoading} />

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box component="span" m={1}>
                <Button
                  variant="outlined"
                  onClick={saveProject}
                  style={{
                    textTransform: "none",
                    color: "white",
                    minHeight: "47px",
                    minWidth: "163px",
                    backgroundColor: "#223647",
                    fontSize: "16px",
                    fontWeight: "700",
                    "&:hover": {
                      backgroundColor: "rgba(34, 54, 71, 0.76)",
                    },
                  }}
                >
                  Save
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {projectType !== "default" && (
                <Box
                  m={1}
                  onClick={() => changeType("default")}
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontWeight: "200",
                    fontSize: "13px",
                    fontFamily: "BatonTurbo-Book",
                  }}
                >
                  Automatic (Order number)
                </Box>
              )}
              {projectType !== "customURL" && (
                <Box
                  m={1}
                  onClick={() => changeType("customURL")}
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontWeight: "200",
                    fontSize: "13px",
                    fontFamily: "BatonTurbo-Book",
                  }}
                >
                  Custom URL
                </Box>
              )}
              {projectType !== "soMeAd" && (
                <Box
                  m={1}
                  onClick={() => changeType("soMeAd")}
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontWeight: "200",
                    fontSize: "13px",
                    fontFamily: "BatonTurbo-Book",
                  }}
                >
                  Social Media Only
                </Box>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CreateProject;
