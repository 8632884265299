import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import {
  Paper,
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  Checkbox,
} from "@mui/material";
import store from "../redux/store";
import { logOut, setErrorMessage } from "../redux/actions/index";
import LoadingSpinner from "./LoadingSpinner";

const User = ({ user, deleteUserFn, setRoleFn }) => {
  const userData = {
    id: user._id,
    status: user.status ? user.status : "not set",
    email: user.google && user.google.email ? user.google.email : user.email,
    name: user.google && user.google.email ? user.google.name : "not set",
    lastActive: moment(user.lastActive).format("YYYY-MM-DD") || null,
    isAdmin: user.admin || false,
  };

  const handleAdminCheckboxChange = (e) => {
    setRoleFn(userData.id, { admin: e.target.checked });
  };

  return (
    <Grid item xs={12} sm={12} md={12}>
      {userData && (
        <Paper
          variant="outlined"
          square
          sx={{
            p: 2,
            px: { sm: 3 },
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              Name: {userData.name}
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              E-Mail: {userData.email}
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              Status: {userData.status}
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              Last activity: {userData.lastActive}
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              Is Admin:
              <Checkbox
                checked={userData.isAdmin}
                onChange={handleAdminCheckboxChange}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Button variant="outlined" onClick={deleteUserFn(userData.id)}>
                Delete
              </Button>
            </Grid>
          </Grid>
        </Paper>
      )}
    </Grid>
  );
};

const Admin = () => {
  const navigate = useNavigate();
  const [userList, setUserList] = useState([]);
  const [inviteeEmail, setInviteeEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getUsers = async () => {
    store.dispatch(setErrorMessage(null));
    setIsLoading(true);

    try {
      const resp = await axios.get(
        `${import.meta.env.VITE_BACKEND_BASE_URL}/v1/users`,
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
          },
        },
      );

      setUserList(resp.data);
    } catch (err) {
      let msg = "Error getting users";
      if (err.response) {
        if (err.response.status === 401) {
          store.dispatch(logOut);
          navigate("/login");
          return;
        }
        msg += `: ${err.response.data}`;
      }
      store.dispatch(setErrorMessage(msg));
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setInviteeEmail(value);
  };

  const deleteUser = (id) => async () => {
    store.dispatch(setErrorMessage(null));

    try {
      await axios.delete(
        `${import.meta.env.VITE_BACKEND_BASE_URL}/v1/users/${id}`,
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
          },
        },
      );

      await getUsers();
    } catch (err) {
      let msg = "Error deleting user";
      if (err.response) {
        if (err.response.status === 401) {
          store.dispatch(logOut());
          navigate("/login");
          return;
        }
        msg += `: ${err.response.data}`;
      }
      store.dispatch(setErrorMessage(msg));
    }
  };

  const setRole = async (id, role) => {
    store.dispatch(setErrorMessage(null));

    try {
      await axios.patch(
        `${import.meta.env.VITE_BACKEND_BASE_URL}/v1/users/${id}`,
        { role },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
          },
        },
      );

      await getUsers();
    } catch (err) {
      let msg = "Error setting user role";
      if (err.response) {
        msg += `: ${err.response.data}`;
      }
      store.dispatch(setErrorMessage(msg));
    }
  };

  const inviteUser = async () => {
    store.dispatch(setErrorMessage(null));
    setIsLoading(true);

    try {
      await axios.post(
        `${import.meta.env.VITE_BACKEND_BASE_URL}/v1/users`,
        { email: inviteeEmail },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
          },
        },
      );

      await getUsers();
    } catch (err) {
      let msg = "Error inviting user";
      if (err.response) {
        msg += `: ${err.response.data}`;
      }
      store.dispatch(setErrorMessage(msg));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">Admin</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Paper
          variant="outlined"
          square
          sx={{
            p: 2,
            px: { sm: 3 },
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant="h6">Invite user</Typography>
            </Grid>
            <Grid item xs={12} sm={9} md={10}>
              <TextField
                name="inviteeEmail"
                id="admin-invitee-email"
                variant="outlined"
                label="E-Mail"
                value={inviteeEmail}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <Box component="span" m={0}>
                <Button variant="outlined" onClick={inviteUser}>
                  Invite
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <LoadingSpinner isLoading={isLoading} />

      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">Users</Typography>
        <Grid item xs={12} sm={12} md={12}>
          {userList.length > 0 ? (
            <Grid container spacing={2}>
              {userList.map((e, i) => (
                <User
                  key={i}
                  user={e}
                  setRoleFn={setRole}
                  deleteUserFn={deleteUser}
                />
              ))}
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant="h5">No users found</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Admin;
